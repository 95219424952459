import {CookieManager} from 'utils/cookie_manager';

const prepareNagivateToOnClick = () => {
  const nodes = document.querySelectorAll('.navigate-to-on-click');

  for (let i = 0; i < nodes.length; i++) {
    let el = nodes[i];
    el.onclick = () => {
      document.location = el.dataset.path;
    };
  }
}

prepareNagivateToOnClick();

const prepareRedirectAfterLogin = () => {
  const nodes = document.querySelectorAll('.redirect-after-login');

  for (let i = 0; i < nodes.length; i++) {
    let el = nodes[i];
    el.onclick = () => {
      CookieManager.set('redirect_to', el.dataset.afterLogin);
    };
  }
}

prepareRedirectAfterLogin();

let clickable = document.querySelectorAll('.toggle-expanded-on-click');
for (let el of clickable) {
  el.onclick = (e) => { e.currentTarget.classList.toggle('expanded'); };
}