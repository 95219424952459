import {CookieManager} from 'utils/cookie_manager'
import {submit_button, element_with_content, element_with_class} from 'utils/material_elements'
import {User} from 'utils/user'
import {params_with_csrf_token} from 'utils/form_utils'
import {xhr} from 'libs/requests'

ok_clicked = ->
  CookieManager.set('gdpr_cookies_notice', true)
  CookieManager.set('gdpr_cookies_notice_timestamp', new Date().getTime())
  document.querySelector('.cookie-message').classList.add('closed')

  window.dataLayer = window.dataLayer || []
  gtag = -> dataLayer.push(arguments)

  gtag 'consent', 'update',
    'ad_storage': 'granted'
    'analytics_storage': 'granted'

  params = params_with_csrf_token()
  params.append('type', 15)
  params.append('label', "Pressed the button labelled 'OK' beside the text 'Our site uses coookies' with a link to 'Find out more'")
  xhr('/events', 'POST', params)

  append_quantcast()

confirm_button = ->
  button = submit_button('OK', true, true)
  button.addEventListener 'click', -> ok_clicked()
  button

cookie_message = ->
  div = element_with_class('div', 'cookie-message')
  div.append element_with_content('span', "Our site uses cookies", 'message')
  div.append confirm_button()
  div.append link_to_more()
  div

link_to_more = ->
  link = element_with_content('a', 'Find out more')
  link.setAttribute('href', '/privacy_policy#cookies')
  link

append_cookie_message = ->
  document.body.append cookie_message()

append_cookie_message_if_not_verified = ->
  append_cookie_message() unless CookieManager.get('gdpr_cookies_notice')

append_quantcast = ->
  script = document.createElement('script')
  script.src = "https://secure.quantserve.com/quant.js"
  script.async = true
  document.body.appendChild(script)

append_quantcast_if_verified = ->
  append_quantcast() if CookieManager.get('gdpr_cookies_notice')

append_cookie_message_if_not_verified()
append_quantcast_if_verified()