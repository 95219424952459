prepare_twitter_lazy_load = ->
  loaded = false
  document.addEventListener 'scroll', ->
    if !loaded and window.scrollY > 20
      if el = document.querySelector('.twitter-card.lazy-load')
        loaded = true
        script = document.createElement('SCRIPT')
        script.setAttribute('async', 'true')
        script.setAttribute('src', 'https://platform.twitter.com/widgets.js')
        script.setAttribute('charset', "utf-8")
        el.append script

document.addEventListener "DOMContentLoaded", prepare_twitter_lazy_load