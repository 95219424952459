if document.body.dataset.amplitude
  amplitude = require('amplitude-js')
  amplitude.getInstance().init(document.body.dataset.amplitude)

  document.addEventListener "DOMContentLoaded", (event) ->
    if document.body.dataset.userId
      amplitude.getInstance().setUserId document.body.dataset.userId
      amplitude.getInstance().identify(new amplitude.Identify().set('name', document.body.dataset.userName))

export class EventTracking
  @track: (name, properties) ->
    if amplitude?
      properties or= {}
      properties['url'] = window.location.pathname
      properties['user_type'] = document.body.dataset.userStatus
      properties['loggedIn'] = document.body.dataset.userId != undefined
      properties['page'] = document.body.getAttribute('id') unless properties['page']
      try
        amplitude.getInstance().logEvent name, properties
    true

  # $(document).on 'click', '.logout', ->
  #   amplitude.getInstance().setUserId(null)

  # $(document).on 'click', '.trackclick', ->
  #   EventTracking.track $(this).data('event'), $(this).data('event-properties')

  # if $('body').attr('id') is 'pricing'
  #   EventTracking.track 'ViewPricing'


  # if $('body').attr('id') is 'optimizer'
  #   EventTracking.track 'ViewOptimizer'

  # if $('body').attr('id') is 'countdown'
  #   EventTracking.track 'ViewCountdown'

  # if $('body').attr('id') is 'portfolios'
  #   EventTracking.track 'ViewGenerator'

  # if $('body').attr('id') is 'company'
  #   EventTracking.track 'ViewShare', {name: $('h1').text()}