import {EmojiFallback} from 'utils/emoji_fallback';
import autoComplete from "libs/autoComplete";
import {MDCTextField} from '@material/textfield';
import {element_with_content} from 'utils/material_elements'

export class CompanySearch {

   constructor(id, includeHoldings, includeLinkToRequestCompany, selectionCallback) {
    this.selectionCallback = selectionCallback;

    this.prepare("#" + id, includeHoldings, includeLinkToRequestCompany);
  }

  prepare(id, includeHoldings, includeLinkToRequestCompany) {
    this.emoji = new EmojiFallback();
    this.prepareAutocomplete(id, includeHoldings, includeLinkToRequestCompany);

    document.querySelector(id).onsubmit = () => { return false; };

    document.querySelector(id + '-box').onblur = () => {
      for (let el of document.querySelectorAll('#autoComplete_list')) {
        setTimeout(() => el.innerHTML = "", 400);
      }
    }

    try {
      new MDCTextField(document.querySelector(id + '-box').parentElement);
    } catch (e) { }
  }

  prepareAutocomplete(id, includeHoldings, includeLinkToRequestCompany) {
    new autoComplete({
      data: {
        src: async () => {
          const query = document.querySelector(id + '-box').value;
          let url = "/suggest.json?";
          if (includeHoldings) url = url + "holdings=true&";
          url = url + "q=" + encodeURIComponent(query);
          const source = await fetch(url);
          const data = await source.json();
          if (includeLinkToRequestCompany) {
            data.push({placeholder:true, search: query, path: "/share_requests/new"});
          }
          return data;
        },
        key: ['search'],
        cache: false
      },
      selector: id + '-box',
      resultsList: {
        render: true,
        destination: document.querySelector(id),
        position: 'beforeend',
        element: 'div'
      },
      threshold: 1,
      debounce: 200,
      maxResults: 10,
      highlight: true,
      resultItem: {
        content: (data, source) => {
          if (data.value.placeholder) {
            source.appendChild(element_with_content('div', "Company missing?", 'placeholder'));
          } else {          
            const flag = document.createElement('div');
            flag.setAttribute('class', 'flag');
            flag.innerHTML = data.value.flag;
            this.emoji.replaceInElement(flag);
            const ticker = document.createElement('div');
            ticker.setAttribute('class', 'ticker');
            ticker.innerHTML = data.value.ticker;
            const name = document.createElement('div');
            name.setAttribute('class', 'name');
            name.innerHTML = data.value.name;
            source.appendChild(flag);
            source.appendChild(ticker);
            source.appendChild(name);
          }
        },
        element: 'div'
      }, onSelection: (feedback) => {
        this.selectionCallback(feedback.selection);
      }
    });
  }
}