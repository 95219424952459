export set_lazy_image_url = (img) ->
  img.src = img.dataset.image
  img.classList.remove "lazy-load"

prepare_lazy_load = ->
  images = [].slice.call(document.querySelectorAll("img.lazy-load"))

  if window.IntersectionObserver
    observer = new IntersectionObserver (entries, observer) ->
      entries.forEach (entry) ->
        if entry.isIntersecting
          set_lazy_image_url entry.target
          observer.unobserve(entry.target)

    images.forEach (image) -> observer.observe(image)

  else
    images.forEach (image) ->
      set_lazy_image_url image

document.addEventListener "DOMContentLoaded", prepare_lazy_load