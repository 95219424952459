require('components/layout')
require('navigation')
require('components/gdpr_cookie_notice')
require('components/lazy_load_images')
require('components/lazy_load_twitter')
require('components/lazy_load_trustpilot')
require('components/flash')

import {MDCList} from '@material/list'
import {EmojiFallback} from 'utils/emoji_fallback'
import {CompanySearch} from 'components/company_search'

new CompanySearch('home-search', false, true, (selection) => document.location = selection.value.path)

emoji = new EmojiFallback()
emoji.replaceInElement document.querySelector('.popular-searches')

prepareSearchListClick = (list) ->
  searchList = new MDCList(list)
  searchList.listen 'MDCList:action', (action) =>
    item = searchList.listElements[action.detail.index]
    document.location = item.dataset.path

prepareSearchListClick(list) for list in document.querySelectorAll('.popular-search-table')
