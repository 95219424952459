export class EmojiFallback

  constructor: ->
    @prepare_string()

  run: ->
    @replace_emojis() unless @emoji_support()

  tree_walker: ->
    nodes = ['A', 'LI', 'TD', 'SPAN']
    document.createTreeWalker document.body, NodeFilter.SHOW_TEXT, ((node) ->
      return NodeFilter.FILTER_SKIP unless node.parentNode?
      return NodeFilter.FILTER_REJECT unless nodes.indexOf(node.parentNode.nodeName.toUpperCase()) >= 0
      return NodeFilter.FILTER_SKIP if String.prototype.trim && !node.nodeValue.trim().length
      return NodeFilter.FILTER_ACCEPT), false

  text_nodes: ->
    nodes = []
    walker = @tree_walker()
    nodes.push(walker.currentNode) while walker.nextNode()
    nodes

  emoji_support: ->
    return false unless document.createElement('canvas').getContext
    context = document.createElement('canvas').getContext('2d')
    return false unless typeof context.fillText == 'function'
    flag = String.fromCodePoint(0x1F1F3) + String.fromCodePoint(0x1F1FF);
    context.textBaseline = "top"
    context.font = "32px Arial"
    context.fillText(flag, 0, 0)
    context.getImageData(16, 16, 1, 1).data[0] != 0

  codePoint: (point) ->
    offset = point - 0x10000
    units = if point > 0xFFFF then [0xD800 + (offset >> 10), 0xDC00 + (offset & 0x3FF)] else [point]
    String.fromCharCode.apply(null, units)

  surrogatePairToCodepoint: (lead, trail) -> (lead - 0xD800) * 0x400 + (trail - 0xDC00) + 0x10000    

  getImageForCodepoint: (hex) ->
    img = document.createElement('IMG')
    img.style.verticalAlign = "top"
    img.classList.add 'emoji-flag'
    img.src = "//cdn.dividendmax.com/flags/#{hex}.png"
    img

  fragmentForString: (htmlString) ->
    tmpDoc = document.createElement('DIV')
    fragment = document.createDocumentFragment()

    tmpDoc.innerHTML = htmlString
    fragment.appendChild(childNode) while childNode = tmpDoc.firstChild
    fragment

  replace_in_node: (node) ->
    pattern = /([\ud800-\udbff])([\udc00-\udfff])([\ud800-\udbff])([\udc00-\udfff])/g
    value = node.nodeValue
    if value.match(pattern)
      replacement = @imageReplacement(value, pattern)
      node.parentNode.replaceChild(@fragmentForString(replacement), node)

  replaceInElement: (element) ->
    return if @emoji_support()
    pattern = /([\ud800-\udbff])([\udc00-\udfff])([\ud800-\udbff])([\udc00-\udfff])/g
    value = element.innerHTML
    if value.match(pattern)
      replacement = @imageReplacement(value, pattern)
      element.innerHTML = replacement

  imageReplacement: (value, pattern) ->
    value.replace pattern, (match, p1, p2, p3, p4) =>
      point1 = @surrogatePairToCodepoint(p1.charCodeAt(0), p2.charCodeAt(0))
      point2 = @surrogatePairToCodepoint(p3.charCodeAt(0), p4.charCodeAt(0))
      img = @getImageForCodepoint(point1.toString(16) + point2.toString(16))
      img.outerHTML

  prepare_string: ->
    unless String.fromCodePoint?
      String.fromCodePoint = =>
        chars = []
        chars.push(@codePoint(point)) for point in arguments
        chars.join("")

  replace_emojis: ->
    @replace_in_node(node) for node in @text_nodes()


export apply = ->
  emoji = new EmojiFallback()
  emoji.run()