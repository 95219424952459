export User = {

  isLoggedIn: -> @type() > 0
  isEmailVerified: -> document.body.dataset.emailVerified != undefined
  isFreeAccount: -> @status() is 'FreeAccount'
  isPaidExpired: -> @status() is 'ExpiredPaid'
  isFreeTrialActive: -> @status() is 'ActiveFreeTrial'
  isFreeTrialExpired: -> @status() is 'ExpiredFreeTrial'
  isPaidActive: -> @status() is 'ActivePaid' or @status() is 'Admin'
  hasActiveSubscription: -> @isFreeTrialActive() or @isPaidActive()

  type: -> document.body.dataset.userType
  isExpired: -> @isLoggedIn() and document.body.dataset.expired != undefined
  status: -> document.body.dataset.userStatus
  hasNotification: (shareId) -> @notificationShareIds().indexOf(shareId) > -1
  notificationCount: (shareId) ->
    index = @notificationShareIds().indexOf(shareId)
    return @notificationQuantities()[index] if index > -1
    null

  notificationShareIds: -> document.body.dataset.notifIds.split(",")
  notificationQuantities: -> document.body.dataset.notifCounts.split(",")
}