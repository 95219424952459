export generate_recaptcha_token = (callback) ->
  grecaptcha.ready ->
    grecaptcha.execute(document.body.dataset.googleSiteKey, {action: 'submit'}).then (token) ->
      document.querySelector('#recaptcha_token').value = token
      callback()

export form_params = (selector) ->
  params = params_with_csrf_token()
  form = document.querySelector(selector)
  append_input_to_params(input, params)  for input in form.querySelectorAll('input')
  append_input_to_params(select, params) for select in form.querySelectorAll('select')
  params

export params_with_csrf_token = ->
  params = new URLSearchParams()
  append_csrf_token(params)
  params

append_input_to_params = (input, params) ->
  params.append(input.name, input.value)

append_csrf_token = (params) ->
  token_name  = document.querySelector('meta[name=csrf-param]').getAttribute('content')
  token_value = document.querySelector('meta[name=csrf-token]').getAttribute('content')
  params.append(token_name, token_value)

export form_element = (url, method, id) ->
  form = document.createElement('FORM')
  form.setAttribute('action', url)
  form.setAttribute('method', method or 'POST')
  form.setAttribute('id', id)
  form

export hidden_field = (name, value) ->
  input = document.createElement('INPUT')
  input.setAttribute('name', name)
  input.setAttribute('type', 'hidden')
  input.value = value
  input