export class CookieManager

  @set: (key, val) ->
    date = new Date()
    date.setTime(date.getTime() + 157680000000)
    document.cookie = "#{key}=#{val};expires=#{date.toUTCString()};path=/"

  @get: (key) ->
    name = "#{key}="
    for c in decodeURIComponent(document.cookie).split(';')
      c = c.trim()
      return c.substring(name.length, c.length) if c.indexOf(name) == 0
    return null

  @remove: (key) ->
    val = CookieManager.get(key)
    if val
      document.cookie = "#{key}=;expires=1970-01-01;path=/"