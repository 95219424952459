import {EmojiFallback} from 'utils/emoji_fallback';
import autoComplete from "./libs/autoComplete";
import {MDCTextField} from '@material/textfield';
import {MDCTextFieldIcon} from '@material/textfield/icon';
import {element_with_content} from 'utils/material_elements';

const emoji = new EmojiFallback();

new autoComplete({
  data: {
    src: async () => {
      const query = document.querySelector('#search-box').value;
      const source = await fetch("/suggest.json?q=" + encodeURIComponent(query));
      const data = await source.json();
      data.push({placeholder:true, search: query, path: "/share_requests/new"});
      return data;
    },
    key: ['search'],
    cache: false
  },
  selector: '#search-box',
  resultsList: {
    render: true,
    destination: document.querySelector('#recessed-search-box form'),
    position: 'beforeend',
    element: 'div'
  },
  maxResults: 11,
  highlight: true,
  resultItem: {
    content: (data, source) => {
      if (data.value.placeholder) {
        source.appendChild(element_with_content('div', "Company missing?", 'placeholder'));
      } else {
        const flag = document.createElement('div');
        flag.setAttribute('class', 'flag');
        flag.innerHTML = data.value.flag;
        emoji.replaceInElement(flag);
        const ticker = document.createElement('div');
        ticker.setAttribute('class', 'ticker');
        ticker.innerHTML = data.value.ticker;
        const name = document.createElement('div');
        name.setAttribute('class', 'name');
        name.innerHTML = data.value.name;
        source.appendChild(flag);
        source.appendChild(ticker);
        source.appendChild(name);
      }
    },
    element: 'div'
  }, onSelection: (feedback) => {
    document.location = feedback.selection.value.path;
  }
});

document.querySelector('header #header-search-icon').addEventListener('click', () => {
  document.querySelector('#recessed-search-box').classList.toggle('shown');
  document.querySelector('#search-box').focus();
});

document.querySelector('#recessed-search-box form').onsubmit = () => { return false; };

const prepare_header_search = () => {
  const textfield = new MDCTextField(document.querySelector('#recessed-search-box label'));
  const icon = new MDCTextFieldIcon(document.querySelector('#recessed-search-box .clear-input'));
  icon.listen('MDCTextField:icon', (event) => {
    document.querySelector('#recessed-search-box').classList.remove('shown');
    document.querySelector('#search-box').blur();
  });
}

prepare_header_search();