import {MDCTextField} from '@material/textfield'

export link_button = (label, url, raised, secondary, outlined) ->
  a = element_with_class('A', 'mdc-button')
  a.setAttribute('href', url)
  a.classList.add 'mdc-button--raised' if raised
  a.classList.add 'mdc-button--outlined' if outlined
  a.classList.add 'mdc-theme--secondary-bg' if secondary
  a.append element_with_class('DIV', 'mdc-button__ripple')
  span = element_with_class('SPAN', 'mdc-button__label')
  span.innerText = label
  a.append span
  a

export submit_button = (label, raised, secondary, outlined) ->
  button = element_with_class('button', 'mdc-button')
  button.classList.add 'mdc-button--raised' if raised
  button.classList.add 'mdc-theme--secondary-bg' if secondary
  button.classList.add 'mdc-button--outlined' if outlined
  button.append element_with_class('div', 'mdc-button__ripple')
  button.append element_with_content('span', label, 'mdc-button__label')
  button

export text_field = (label, name, value, extra_classes...) ->
  el = element_with_class('label', 'mdc-text-field', 'mdc-text-field--filled', extra_classes...)
  el.append element_with_class('span', 'mdc-text-field__ripple')
  input = element_with_class('input', 'mdc-text-field__input')
  input.setAttribute('label', label)
  input.setAttribute('type', 'text')
  input.setAttribute('name', name)
  input.value = value
  el.append input
  el.append element_with_content('span', label, 'mdc-floating-label')
  el.append element_with_class('span', 'mdc-line-ripple')
  el

export money_input_field = (label, name, value, symbol, extra_classes...) ->
  el = element_with_class('label', 'mdc-text-field', 'mdc-text-field--filled', extra_classes...)
  el.append element_with_class('span', 'mdc-text-field__ripple')
  el.append element_with_content('span', symbol, 'mdc-text-field__affix', 'mdc-text-field__affix--prefix', 'currency-symbol')
  input = element_with_class('input', 'mdc-text-field__input')
  input.setAttribute('label', label)
  input.setAttribute('type', 'text')
  input.setAttribute('name', name)
  input.setAttribute 'pattern', "[0-9\\.]*"
  input.setAttribute 'inputmode', "decimal"
  input.value = value
  el.append input
  floatingLabel = element_with_content('span', label, 'mdc-floating-label')
  el.append floatingLabel
  el.append element_with_class('span', 'mdc-line-ripple')
  el

export element_with_class = (element, classes...) ->
  el = document.createElement(element.toUpperCase())
  el.classList.add c for c in classes when c isnt null
  el

export element_with_content = (element, content, classes...) ->
  el = element_with_class(element, classes...)
  if typeof(content) == 'string' and content[0] == '&'
    el.innerHTML = content
  else
    el.append content
  el


export material_select_option = (label, value, selected) ->
  li = element_with_class('li', "mdc-list-item")
  li.dataset.value = value
  li.setAttribute('role', 'option')
  li.setAttribute('aria-selected', 'true') if selected
  li.append element_with_class('span', "mdc-list-item__ripple")
  li.append element_with_content('span', label, "mdc-list-item__text")
  li

export material_select = (label) ->
  select = element_with_class('div', 'mdc-select', 'mdc-select--filled')
  select.append select_anchor(label)

  menu = element_with_class('div', "mdc-select__menu", "mdc-menu", "mdc-menu-surface")
  ul = element_with_class('ul', 'mdc-list')
  ul.setAttribute('role', 'listbox')
  ul.setAttribute('aria-label', label)
  menu.append ul
  select.append menu
  select

select_anchor = (label) ->
  anchor = element_with_class('div', 'mdc-select__anchor')
  anchor.setAttribute('role', 'button')
  anchor.setAttribute('aria-haspopup', 'listbox')
  anchor.setAttribute('aria-expanded', 'false')
  anchor.append element_with_class('span', 'mdc-select__ripple')
  anchor.append element_with_content('span', label, 'mdc-floating-label')
  anchor.append element_with_class('span', 'mdc-select__selected-text')
  anchor.append dropdown_icon()
  anchor.append element_with_class('span', 'mdc-line-ripple')
  anchor

dropdown_icon = ->
  svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  svg.classList.add "mdc-select__dropdown-icon-graphic"
  svg.setAttributeNS null, 'viewBox', "7 10 10 5"
  svg.setAttributeNS null, 'focusable', "false"
  inactive_polygon = document.createElementNS('http://www.w3.org/2000/svg', 'polygon')
  inactive_polygon.classList.add "mdc-select__dropdown-icon-inactive"
  inactive_polygon.setAttributeNS null, 'stroke', "none"
  inactive_polygon.setAttributeNS null, 'fill-rule', "evenodd"
  inactive_polygon.setAttributeNS null, 'points', "7 10 12 15 17 10"

  active_polygon = document.createElementNS('http://www.w3.org/2000/svg', 'polygon')
  active_polygon.classList.add "mdc-select__dropdown-icon-active"
  active_polygon.setAttributeNS null, 'stroke', "none"
  active_polygon.setAttributeNS null, 'fill-rule', "evenodd"
  active_polygon.setAttributeNS null, 'points', "7 15 12 10 17 15"

  svg.appendChild inactive_polygon
  svg.appendChild active_polygon
  element_with_content('span', svg, "mdc-select__dropdown-icon")