import {MDCTabIndicator} from '@material/tab-indicator'
import {MDCRipple} from '@material/ripple'
import {MDCTabBar} from '@material/tab-bar'
import {MDCTopAppBar} from "@material/top-app-bar"
import {MDCDrawer} from "@material/drawer"

selector = '.mdc-button, .mdc-icon-button, .mdc-card__primary-action'
[].map.call document.querySelectorAll(selector), (el) ->
  new MDCRipple(el)

[].map.call document.querySelectorAll('.mdc-tab-indicator'), (el) ->
  new MDCTabIndicator(el)


drawer = MDCDrawer.attachTo(document.querySelector('.mdc-drawer'))

topAppBar = MDCTopAppBar.attachTo(document.getElementById('app-bar'))
topAppBar.setScrollTarget(document.getElementById('content'))
topAppBar.listen 'MDCTopAppBar:nav', -> drawer.open = !drawer.open

tabBar = new MDCTabBar(document.querySelector('.mdc-tab-bar'))

require('utils/amplitude')
require('site_search')
require('navigation')